// src/components/ui/Banner.js

import React from "react";

const Banner = () => {
  return (
    <div className="flex items-center justify-center space-x-4 bg-green-700 p-2">
      <a
        className="text-white font-bold text-lg"
        href="https://chat.whatsapp.com/Lg9IDCx4SId55I5i8yPiVm" target="_blank" rel="noreferrer">
        Community
      </a>
    </div>
  );
};

export default Banner;
