import React from 'react';
import Roaster from '../components/sections/Roster';
import Sponsors from '../components/sections/Sponsors';
import HeaderRoaster from '../components/ui/HeaderRoster';

const RoasterPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-28">
            <HeaderRoaster />
            <main>
                <Roaster />
                <Sponsors />
            </main>
        </div>
    );
};

export default RoasterPage;
