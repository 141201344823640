import { useState, useEffect, useRef } from 'react';
import { toast } from 'wc-toast';
import PocketBase from 'pocketbase';
import JSConfetti from 'js-confetti';

const pb = new PocketBase('https://hw0kcow4wswsg8k0gookww0o.app-palma.teide.app');

const Roaster = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    birthdate: '',
    playerid: '',
    selectedCategories: [],
    phone: '',
    discord: '',
    extension: ''
  });

  // Create a ref for confetti
  const jsConfettiRef = useRef(null);

  useEffect(() => {
    // Initialize confetti when component mounts
    jsConfettiRef.current = new JSConfetti();

    return () => {
      // Clean up when component unmounts
      if (jsConfettiRef.current) {
        jsConfettiRef.current.clearCanvas();
      }
    };
  }, []);

  // Determine player's division based on birthdate
  const calculateDivision = (birthdate) => {
    if (!birthdate) return '';

    const today = new Date();
    const birth = new Date(birthdate);
    const age = today.getFullYear() - birth.getFullYear();

    // Adjust age if birthday hasn't occurred yet this year
    if (today.getMonth() < birth.getMonth() ||
      (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate())) {
      const adjustedAge = age - 1;

      if (adjustedAge < 12) return 'junior';
      if (adjustedAge < 18) return 'senior';
      return 'master';
    }

    if (age < 12) return 'junior';
    if (age < 18) return 'senior';
    return 'master';
  };

  useEffect(() => {
    // Create an AbortController to handle request cancellation
    const abortController = new AbortController();

    const loadCategories = async () => {
      try {
        const result = await pb.collection('categories').getList(1, 50, {
          filter: 'public = true',
          signal: abortController.signal
        });
        setCategories(result.items);
      } catch (error) {
        // Only show error if it's not a cancellation
        if (!error.message || !error.message.includes('autocancelled')) {
          console.error('Error fetching categories:', error);
          toast.error('Error loading game categories');
        }
      }
    };

    loadCategories();

    // Clean up function to cancel any pending requests when component unmounts
    return () => {
      abortController.abort();
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (categoryId) => {
    setFormData(prev => {
      const updated = { ...prev };

      if (updated.selectedCategories.includes(categoryId)) {
        updated.selectedCategories = updated.selectedCategories.filter(id => id !== categoryId);
      } else {
        updated.selectedCategories = [...updated.selectedCategories, categoryId];
      }

      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.selectedCategories.length === 0) {
      toast.error('Please select at least one game category');
      return;
    }

    setLoading(true);

    try {
      // Get the first category ID to use as the primary category
      const primaryCategoryId = formData.selectedCategories[0];

      // Create the player record first
      const playerData = {
        name: formData.name,
        surname: formData.surname,
        birthdate: formData.birthdate,
        playerid: formData.playerid,
        category: primaryCategoryId, // Add the required category field
        phone: formData.phone || '', // Ensure these have default values
        discord: formData.discord || '',
        extension: formData.extension || ''
      };

      console.log('Creating player with data:', playerData);
      const player = await pb.collection('players').create(playerData);
      console.log('Player created successfully:', player.id);

      // For each selected category, create a registration
      const division = calculateDivision(formData.birthdate);

      // Handle player categories registration one by one to isolate potential errors
      for (const categoryId of formData.selectedCategories) {
        try {
          await pb.collection('player_categories').create({
            player: player.id,
            category: categoryId,
            division: division
          });
          console.log(`Category ${categoryId} registered successfully`);
        } catch (categoryError) {
          console.error(`Error registering category ${categoryId}:`, categoryError);
          // Continue with other categories even if one fails
        }
      }

      // Show success toast and trigger confetti
      toast.success('Successfully registered to the club!');

      // Launch confetti celebration
      if (jsConfettiRef.current) {
        jsConfettiRef.current.addConfetti({
          confettiColors: [
            '#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7',
            '#0d41e1', '#1e90ff', '#17c3b2', '#ffbe0b'
          ],
          confettiNumber: 500,
        });
      }

      // Reset the form after a successful registration
      setFormData({
        name: '',
        surname: '',
        birthdate: '',
        playerid: '',
        selectedCategories: [],
        phone: '',
        discord: '',
        extension: ''
      });
      setFormStep(1);

    } catch (error) {
      console.error('Error registering player:', error);

      // Check if this is a network error
      if (error.name === 'AbortError' || error.message?.includes('network')) {
        toast.error('Network error. Please check your connection.');
      } else if (error.status === 400) {
        // Handle validation errors
        toast.error('Please check your form data and try again.');
      } else {
        toast.error('Failed to register. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Group categories by game
  const categoriesByGame = categories.reduce((acc, category) => {
    if (!acc[category.game]) {
      acc[category.game] = [];
    }
    acc[category.game].push(category);
    return acc;
  }, {});

  // Define the custom order for games
  const gameOrder = ['pocket', 'tcg', 'vgc', 'go', 'unite'];

  // Function to sort games according to custom order
  const sortedGameEntries = Object.entries(categoriesByGame).sort((a, b) => {
    const indexA = gameOrder.indexOf(a[0].toLowerCase());
    const indexB = gameOrder.indexOf(b[0].toLowerCase());

    // If game is not in our predefined order, put it at the end
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    // Sort according to the predefined order
    return indexA - indexB;
  });

  const nextStep = () => {
    // When on step 1, validate required fields before proceeding
    if (formStep === 1) {
      // Check required fields
      if (!formData.name || !formData.surname || !formData.birthdate) {
        toast.error('Please fill out all required fields before continuing');

        // Highlight the missing fields
        const missingFields = [];
        if (!formData.name) missingFields.push('First Name');
        if (!formData.surname) missingFields.push('Last Name');
        if (!formData.birthdate) missingFields.push('Date of Birth');

        if (missingFields.length > 0) {
          toast.error(`Missing fields: ${missingFields.join(', ')}`);
        }
        return;
      }

      // Validate player ID format if provided
      if (formData.playerid) {
        // Check if it's a 7-digit number
        const playerIdPattern = /^\d{7}$/;
        if (!playerIdPattern.test(formData.playerid)) {
          toast.error('Player ID must be a 7-digit number');
          return;
        }
      }
    }
    // When on step 2, validate that at least one category is selected
    else if (formStep === 2) {
      if (formData.selectedCategories.length === 0) {
        toast.error('Please select at least one game category');
        return;
      }
    }

    // If validation passes, proceed to next step
    setFormStep(current => current + 1);
  };

  const prevStep = () => setFormStep(current => current - 1);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-red-900 px-4 py-10">
      <wc-toast></wc-toast>

      <div className="max-w-3xl mx-auto bg-white/10 backdrop-blur-md rounded-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-red-500 to-red-800 p-6 text-center">
          <h1 className="text-3xl font-bold text-white mb-2">EpicEvils eSports Club</h1>
          <p className="text-red-100">Join our competitive gaming roster and represent EpicEvils!</p>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-gray-800 h-2">
          <div
            className="bg-gradient-to-r from-red-500 to-red-600 h-2 transition-all duration-300"
            style={{ width: `${(formStep / 3) * 100}%` }}
          ></div>
        </div>

        <div className="p-6 text-white">
          <form onSubmit={handleSubmit} className="space-y-6">
            {formStep === 1 && (
              <div className="space-y-6 animate-fadeIn">
                <h2 className="text-xl font-semibold flex items-center">
                  <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 4)"><path d="m2.5.5h10c1.1045695 0 2 .8954305 2 2v8c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2v-8c0-1.1045695.8954305-2 2-2z" /><path d="m9.5 6.5h2c.5522847 0 1 .44771525 1 1v2c0 .5522847-.4477153 1-1 1h-2c-.55228475 0-1-.4477153-1-1v-2c0-.55228475.44771525-1 1-1z" fill="currentColor" /></g></svg>
                  Personal Information
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-red-100">First Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent text-white"
                      required
                      placeholder="Enter your first name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-red-100">Last Name</label>
                    <input
                      type="text"
                      name="surname"
                      value={formData.surname}
                      onChange={handleInputChange}
                      className="w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent text-white"
                      required
                      placeholder="Enter your last name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-red-100 flex items-center">
                      <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(2 2)"><path d="m2.5.5h12c1.1045695 0 2 .8954305 2 2v12c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-12c0-1.1045695.8954305-2 2-2z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" /><path d="m.5 4.5h16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" /><g fill="currentColor"><circle cx="8.5" cy="8.5" r="1" /><circle cx="4.5" cy="8.5" r="1" /><circle cx="4.5" cy="12.5" r="1" /></g></g></svg>
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="birthdate"
                      value={formData.birthdate}
                      onChange={handleInputChange}
                      className="w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent text-white"
                      required
                    />
                    {formData.birthdate && (
                      <div className="flex items-center mt-2">
                        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 3)"><path d="m4.5.5h6c.5522847 0 1 .44771525 1 1v5c0 2.209139-1.790861 4-4 4s-4-1.790861-4-4v-5c0-.55228475.44771525-1 1-1z" /><path d="m7.5 10.5v3" /><path d="m4.5 13.5h6c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-6c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm7-11h2c.5522847 0 1 .44771525 1 1v1c0 1.1045695-.8954305 2-2 2h-1zm-8 0h-2c-.55228475 0-1 .44771525-1 1v1c0 1.1045695.8954305 2 2 2h1z" /></g></svg>
                        <p className="text-sm text-red-300 font-semibold">
                          Division: {calculateDivision(formData.birthdate).toUpperCase()}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-red-100">Player ID (optional)</label>
                    <input
                      type="text"
                      name="playerid"
                      value={formData.playerid}
                      onChange={handleInputChange}
                      className="w-full p-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent text-white"
                      placeholder="Enter your 7-digit player ID"
                    />
                    <p className="text-xs text-gray-400 mt-1">If you have one, enter your 7-digit player ID</p>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={nextStep}
                    className="bg-red-600 hover:bg-red-700 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-200 transform hover:scale-105 flex items-center"
                  >
                    Next Step
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            )}

            {formStep === 2 && (
              <div className="space-y-6 animate-fadeIn">
                <h2 className="text-xl font-semibold flex items-center">
                  <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 3)"><path d="m4.5.5h6c.5522847 0 1 .44771525 1 1v5c0 2.209139-1.790861 4-4 4s-4-1.790861-4-4v-5c0-.55228475.44771525-1 1-1z" /><path d="m7.5 10.5v3" /><path d="m4.5 13.5h6c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-6c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm7-11h2c.5522847 0 1 .44771525 1 1v1c0 1.1045695-.8954305 2-2 2h-1zm-8 0h-2c-.55228475 0-1 .44771525-1 1v1c0 1.1045695.8954305 2 2 2h1z" /></g></svg>
                  Game Categories
                </h2>

                <p className="text-red-200 text-sm">
                  Select the game categories you want to participate in:
                </p>

                <div className="space-y-6 max-h-[400px] overflow-y-auto pr-2 custom-scrollbar">
                  {sortedGameEntries.map(([game, gameCategories]) => (
                    <div key={game} className="rounded-lg bg-gray-800/50 p-4 border border-gray-700">
                      <h3 className="font-bold text-lg text-red-300 capitalize mb-3 flex items-center">
                        <span className="w-2 h-6 bg-red-500 rounded-full mr-2"></span>
                        {game}
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {gameCategories.map(category => (
                          <div
                            key={category.id}
                            className={`flex items-center space-x-2 p-3 rounded-lg transition-all cursor-pointer
                                                            ${formData.selectedCategories.includes(category.id)
                                ? 'bg-red-600/30 border border-red-500'
                                : 'bg-gray-700/30 border border-gray-600 hover:bg-gray-700/60'}`}
                            onClick={() => handleCheckboxChange(category.id)}
                          >
                            <input
                              type="checkbox"
                              id={`category-${category.id}`}
                              checked={formData.selectedCategories.includes(category.id)}
                              onChange={() => handleCheckboxChange(category.id)}
                              className="h-4 w-4 text-red-600 rounded"
                            />
                            <label htmlFor={`category-${category.id}`} className="text-sm text-white cursor-pointer flex-1">
                              {game.toUpperCase()} - <span className="font-semibold text-red-300">{category.division.charAt(0).toUpperCase() + category.division.slice(1)}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-200"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 inline" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={nextStep}
                    className="bg-red-600 hover:bg-red-700 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-200 transform hover:scale-105 flex items-center"
                    disabled={formData.selectedCategories.length === 0}
                  >
                    Next Step
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            )}

            {formStep === 3 && (
              <div className="space-y-6 animate-fadeIn">
                <h2 className="text-xl font-semibold flex items-center">
                  Contact Information
                </h2>

                <div className="space-y-4">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-red-100">Discord Username</label>
                    <div className="relative">
                      {/* Fixed Discord SVG - proper JSX attributes */}
                      <div className="absolute top-2 left-2">
                        <svg width="24" height="24" viewBox="0 0 256 199" xmlns="http://www.w3.org/2000/svg">
                          <path d="M216.856 16.597C200.285 8.843 182.566 3.208 164.042 0c-2.275 4.113-4.933 9.645-6.766 14.046-19.692-2.961-39.203-2.961-58.533 0-1.832-4.4-4.55-9.933-6.846-14.046-18.545 3.208-36.284 8.863-52.855 16.638C17.861 71.445 3.225 125.461 9.683 178.382c17.14 13.536 39.12 23.61 60.242 30.173 5.215-7.178 9.876-14.808 13.879-22.841-7.631-2.9-14.942-6.478-21.835-10.632 1.833-1.358 3.625-2.777 5.356-4.237 42.122 19.702 87.89 19.702 129.51 0 1.751 1.46 3.543 2.879 5.356 4.237-6.914 4.154-14.242 7.731-21.874 10.632 4.006 8.015 8.638 15.664 13.879 22.841 21.143-6.563 43.147-16.637 60.255-30.173C257.08 119.414 239.191 58.697 203.529 16.598l13.327-.001zm-130.482 99.41c-11.645 0-21.23-11.804-21.23-26.18 0-14.375 9.37-26.197 21.23-26.197 11.86 0 22.22 11.804 22 26.197.021 14.376-10.14 26.18-22 26.18zm81.265 0c-11.645 0-21.21-11.804-21.21-26.18 0-14.375 9.365-26.197 21.21-26.197 11.858 0 22.22 11.804 21.998 26.197 0 14.376-10.12 26.18-21.998 26.18z" fill="#fff" fillRule="evenodd" />
                        </svg>
                      </div>
                      <input
                        type="text"
                        name="discord"
                        value={formData.discord}
                        onChange={handleInputChange}
                        className="w-full p-3 pl-10 bg-gray-800/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent text-white"
                        placeholder="YourUsername#0000"
                      />
                    </div>
                    <p className="text-xs text-gray-400 mt-1">Include your full Discord username.</p>
                  </div>

                  <div className="pt-4 border-t border-gray-700">
                    <div className="flex items-center space-x-3 p-4 bg-gray-800 rounded-lg">
                      <input
                        type="checkbox"
                        id="gdpr"
                        required
                        className="w-5 h-5 text-red-600 rounded focus:ring-red-500"
                      />
                      <label htmlFor="gdpr" className="text-sm text-gray-300">
                        I accept the <span className="text-red-400 hover:underline cursor-pointer">GDPR policy</span>. My data will only be used for club activities.
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-between pt-4">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-200"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      Back
                    </button>
                    <button
                      type="submit"
                      disabled={loading}
                      className={`${loading ? 'bg-gray-600' : 'bg-gradient-to-r from-red-500 to-red-800 hover:from-red-700 hover:to-red-800'
                        } text-white font-medium py-2.5 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 flex items-center`}
                    >
                      {loading ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </>
                      ) : (
                        <>
                          Join EpicEvils
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>

        <div className="bg-gradient-to-r from-gray-800 to-gray-900 p-4 text-center">
          <p className="text-sm text-gray-400">© {new Date().getFullYear()} EpicEvils eSports Club. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Roaster;