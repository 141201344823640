import React from 'react';
import Clothes from '../components/Clothes';
import Sponsors from '../components/sections/Sponsors';
import HeaderClothes from '../components/ui/HeaderClothes';

const TeamPage = () => {
  return (
    <div className="bg-rose-100 min-h-screen py-28">
      <HeaderClothes />
      <main>
        <Clothes />
        <Sponsors />
      </main>
    </div>
  );
};

export default TeamPage;
