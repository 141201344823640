import React from 'react';

const teamMembers = [
    {
        name: 'Alejandro Llamas',
        role: 'Master Competitor',
        description: 'Core member of EpicEvils. Alejandro is a skilled Pokémon TCG player with a passion for the game.',
        image: '/alejandro.jpg'
    }
];


const Team = () => {
    return (
        <div className="container mx-auto py-12 px-4 md:px-8 text-black">
            <section className="flex flex-wrap justify-center">
                {teamMembers.map((member, index) => (
                    <div key={index} className="max-w-md rounded overflow-hidden m-4 bg-[#f00e3305] border border-2 border-[#f00e33]">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{member.name}</div>
                            <p className="text-gray-700 text-base">{member.role}</p>
                            <p className="text-gray-700 text-base mt-2">{member.description}</p>
                        </div>
                        <img className="w-full" src={member.image} alt={member.name} />
                    </div>
                ))}
            </section>
        </div>
    );
};

export default Team;

/* 
Alejandro
https://raw.githubusercontent.com/pencil-li/epicevilsassets/d1578fa5ecb822c9428c6cfecf697ff438eb3a24/com/events/010124/1000/alejandro.jpg?token=GHSAT0AAAAAACP7WCOCOOX2337JTJQLUPJMZSLDGGQ
*/