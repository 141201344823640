import React from 'react';
import Team from '../components/Team';
import Sponsors from '../components/sections/Sponsors';
import HeaderTeam from '../components/ui/HeaderTeam';

const TeamPage = () => {
  return (
    <div className="bg-rose-100 min-h-screen py-28">
      <HeaderTeam />
      <main>
        <Team />
        <Sponsors />
      </main>
    </div>
  );
};

export default TeamPage;
