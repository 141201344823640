import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenuEpicEvils from "./components/ui/MenuEpicEvils";
import Footer from "./components/ui/Footer";
import RosterPage from "./pages/RosterPage";
import CollabPage from "./pages/CollabPage";
import ContactPage from "./pages/ContactPage";
import StandingsPage from "./pages/StandingsPage";
import TeamPage from "./pages/TeamPage";
import ClothesPage from "./pages/ClothesPage";
import LoginPage from "./pages/LoginPage";
import WelcomeNonAlienPage from "./pages/WelcomeNonAlienPage";

import './style.css';
import './effects.css';

function HomePage() {
  return (
    <>
      {/* Hero Section - Bento Style */}
      <section className="relative z-10 py-20 px-4 bg-gradient-to-b from-red-900 via-red-950 to-black overflow-hidden">
        <div className="max-w-6xl mx-auto relative">
          {/* Bento hero layout */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="md:col-span-3 text-center mb-6">
              <h1 className="py-20 text-5xl md:text-6xl font-bold mb-4 bg-gradient-to-r from-red-400 via-red-600 to-red-800 text-transparent bg-clip-text drop-shadow-glow">
                Play Beyond the Game
              </h1>
              <p className="text-lg md:text-xl text-gray-300 max-w-2xl mx-auto">
                Join the eSports revolution. Tools, Teams & Tournaments, all in one place.
              </p>
            </div>

            {/* Business tools bento cards */}
            <div className="bg-black/60 backdrop-blur-sm border border-red-800 p-6 rounded-3xl transition-all hover:border-red-500 shadow-lg hover:shadow-red-900/30 group">
              <h3 className="text-xl font-bold text-red-400 flex items-center">
                <span className="bg-gradient-to-br from-red-700 to-red-900 w-10 h-10 rounded-xl flex items-center justify-center mr-3 shadow-inner group-hover:scale-110 transition-transform">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </span>
                DeckMaker
              </h3>
              <p className="text-gray-400 mt-3 mb-4">Add cards to your stock as a reseller. Manage your inventory with ease.</p>
              <a href="https://deckmaker.tcgse.com" className="inline-flex items-center text-red-500 hover:text-red-400 transition-colors"
                target="_blank" rel="noopener noreferrer">
                Go to DeckMaker
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </a>
            </div>

            <div className="bg-black/60 backdrop-blur-sm border border-red-800 p-6 rounded-3xl transition-all hover:border-red-500 shadow-lg hover:shadow-red-900/30 group">
              <h3 className="text-xl font-bold text-red-400 flex items-center">
                <span className="bg-gradient-to-br from-red-700 to-red-900 w-10 h-10 rounded-xl flex items-center justify-center mr-3 shadow-inner group-hover:scale-110 transition-transform">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                TCGSE
              </h3>
              <p className="text-gray-400 mt-3 mb-4">The Trading Card Game Stock Exchange. Track market prices in real-time.</p>
              <a href="https://tcgse.com" className="inline-flex items-center text-red-500 hover:text-red-400 transition-colors"
                target="_blank" rel="noopener noreferrer">
                Check Prices
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </a>
            </div>

            <div className="bg-black/60 backdrop-blur-sm border border-red-800 p-6 rounded-3xl transition-all hover:border-red-500 shadow-lg hover:shadow-red-900/30 group">
              <h3 className="text-xl font-bold text-red-400 flex items-center">
                <span className="bg-gradient-to-br from-red-700 to-red-900 w-10 h-10 rounded-xl flex items-center justify-center mr-3 shadow-inner group-hover:scale-110 transition-transform">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </span>
                League Platform
              </h3>
              <p className="text-gray-400 mt-3 mb-4">Manage tournaments for official events. Streamline your operations.</p>
              <a href="https://play.epicevils.com" className="inline-flex items-center text-red-500 hover:text-red-400 transition-colors"
                target="_blank" rel="noopener noreferrer">
                Start Hosting
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Bento Grid Layout Main Content */}
      <main className="px-4 py-16 space-y-32 relative">
        {/* Red bamboo accent */}
        <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-red-900/0 via-red-900 to-red-900/0"></div>
        <div className="absolute right-0 top-0 bottom-0 w-1 bg-gradient-to-b from-red-900/0 via-red-900 to-red-900/0"></div>

        {/* Roster Section - Bento Style */}
        <section id="roaster" className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-block p-1 bg-gradient-to-r from-red-900/50 via-red-600 to-red-900/50 rounded-xl mb-3">
              <h2 className="text-3xl md:text-5xl font-bold text-red-400 bg-black px-6 py-2 rounded-lg">
                Join Our Roster
              </h2>
            </div>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto mt-4">
              We're recruiting passionate players ready to compete at the highest level
            </p>
          </div>

          {/* Bento grid layout for roster */}
          <div className="grid grid-cols-1 md:grid-cols-6 gap-6 mb-12">
            {/* TCG Pocket - Large Card */}
            <div className="md:col-span-3 bg-gradient-to-br from-black to-red-950 p-6 rounded-3xl border border-red-800 hover:border-red-500 transition-all shadow-lg group">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4 bg-red-900/30 p-3 rounded-2xl group-hover:scale-110 transition-transform">📱</span>
                <h3 className="text-2xl font-bold text-red-400">TCG Pocket</h3>
              </div>
              <p className="text-gray-300 mb-6 text-lg">Looking for skilled mobile players to represent EpicEvils in tournaments. Compete and grow with our team.</p>
              <div className="pt-3 border-t border-red-900/50 flex justify-between items-center">
                <p className="font-medium text-red-300">📱<span className="text-white">Positions:</span> 2 Players</p>
                <span className="text-xs px-3 py-1 bg-red-900/50 rounded-full text-red-100">Mobile</span>
              </div>
            </div>

            {/* TCG - Medium Card */}
            <div className="md:col-span-3 bg-gradient-to-br from-black to-red-950 p-6 rounded-3xl border border-red-800 hover:border-red-500 transition-all shadow-lg group">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4 bg-red-900/30 p-3 rounded-2xl group-hover:scale-110 transition-transform">🃏</span>
                <h3 className="text-2xl font-bold text-red-400">TCG</h3>
              </div>
              <p className="text-gray-300 mb-6 text-lg">Join our competitive card game team and dominate the tournament scene. Become part of a winning tradition.</p>
              <div className="pt-3 border-t border-red-900/50 flex justify-between items-center">
                <p className="font-medium text-red-300"><span className="text-white">Positions:</span> 2 Players</p>
                <span className="text-xs px-3 py-1 bg-red-900/50 rounded-full text-red-100">Physical</span>
              </div>
            </div>

            {/* VGC - Medium Card */}
            <div className="md:col-span-2 bg-gradient-to-br from-black to-red-950 p-6 rounded-3xl border border-red-800 hover:border-red-500 transition-all shadow-lg group">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4 bg-red-900/30 p-3 rounded-2xl group-hover:scale-110 transition-transform">🎮</span>
                <h3 className="text-2xl font-bold text-red-400">VGC</h3>
              </div>
              <p className="text-gray-300 mb-6">Compete in Video Game Championships as part of our growing team</p>
              <div className="pt-3 border-t border-red-900/50 flex justify-between items-center">
                <p className="font-medium text-red-300"><span className="text-white">Positions:</span> 1-2 Players</p>
                <span className="text-xs px-3 py-1 bg-red-900/50 rounded-full text-red-100">Console</span>
              </div>
            </div>

            {/* GO - Small Card */}
            <div className="md:col-span-2 bg-gradient-to-br from-black to-red-950 p-6 rounded-3xl border border-red-800 hover:border-red-500 transition-all shadow-lg group">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4 bg-red-900/30 p-3 rounded-2xl group-hover:scale-110 transition-transform">📍</span>
                <h3 className="text-2xl font-bold text-red-400">GO</h3>
              </div>
              <p className="text-gray-300 mb-6">Represent us in GO competitions and community events</p>
              <div className="pt-3 border-t border-red-900/50 flex justify-between items-center">
                <p className="font-medium text-red-300"><span className="text-white">Positions:</span> 1-2 Players</p>
                <span className="text-xs px-3 py-1 bg-red-900/50 rounded-full text-red-100">Mobile</span>
              </div>
            </div>

            {/* Unite - Medium Card */}
            <div className="md:col-span-2 bg-gradient-to-br from-black to-red-950 p-6 rounded-3xl border border-red-800 hover:border-red-500 transition-all shadow-lg group">
              <div className="flex items-center mb-4">
                <span className="text-4xl mr-4 bg-red-900/30 p-3 rounded-2xl group-hover:scale-110 transition-transform">🗺️</span>
                <h3 className="text-2xl font-bold text-red-400">Unite</h3>
              </div>
              <p className="text-gray-300 mb-6">Form a complete team to dominate the Unite competitive scene</p>
              <div className="pt-3 border-t border-red-900/50 flex justify-between items-center">
                <p className="font-medium text-red-300"><span className="text-white">Positions:</span>Team of 5</p>
                <span className="text-xs px-3 py-1 bg-red-900/50 rounded-full text-red-100">Console</span>
              </div>
            </div>
          </div>

          <div className="text-center mt-10">
            <a href="/roaster" className="inline-block px-10 py-5 bg-gradient-to-r from-red-800 via-red-700 to-red-800 hover:from-red-700 hover:to-red-600 text-white font-bold rounded-2xl transition-all shadow-lg hover:shadow-red-700/50">
              <span className="flex items-center">
                <span>Apply to Join Our Team</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </span>
            </a>
          </div>
        </section>

        {/* Collab Section - Bento Style */}
        <section id="collab" className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 items-center">
            <div className="md:col-span-3 bg-black/40 backdrop-blur-sm border border-red-800 p-8 rounded-3xl shadow-lg order-2 md:order-1">
              <h2 className="text-3xl md:text-4xl font-bold text-red-400 mb-4">inZoi Collaboration</h2>
              <p className="text-gray-300 text-lg mb-6">Celebrate the beta release of inZoi on Steam. Official merch now! Join the inZoi community and get exclusive access to our collab events.</p>
              <div className="flex flex-wrap gap-4">
                <a href="/collab" className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-red-700 to-red-600 text-white font-semibold rounded-xl hover:from-red-600 hover:to-red-500 transition-all shadow-md hover:shadow-red-700/30">
                  Explore the Collab
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </a>
                <a href="https://news.pencil.li/posts/epicevils-x-inzoi" target="_blank" rel="noopener noreferrer" className="inline-flex items-center px-4 py-3 bg-black border border-red-700 text-red-400 font-semibold rounded-xl hover:bg-red-950 hover:border-red-600 transition-all">
                  Newsroom
                </a>
              </div>
            </div>
            <div className="md:col-span-2 order-1 md:order-2">
              <div className="relative">
                <div className="absolute -inset-4 bg-gradient-to-tr from-red-900 via-red-700 to-red-800 blur-lg opacity-70"></div>
                <img src="https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/002.jpg" alt="inZoi Collaboration"
                  className="relative rounded-2xl shadow-lg w-full object-cover" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="bg-black text-white min-h-screen font-sans">
        <MenuEpicEvils />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/roster" element={<RosterPage />} />
          <Route path="/collab" element={<CollabPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/standings" element={<StandingsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/clothes" element={<ClothesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/welcome-non-alien-resident" element={<WelcomeNonAlienPage />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;