import React from 'react';

const Sponsors = () => {
    return (
        <div>
            <section className="bg-rose-100 py-12 text-black">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center">
                        Our Sponsors
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 justify-items-center">
                        {sponsors.map((sponsor) => (
                            <a key={sponsor.name} href={sponsor.url} target="_blank" rel="noreferrer" className="flex flex-col items-center text-center">
                                <img src={sponsor.logo} alt={sponsor.name} className="rounded-full h-20 mb-2" />
                                <span className={`font-bold text-xl ${sponsor.textColor}`}>{sponsor.name}</span>
                            </a>
                        ))}
                    </div>

                </div>
            </section>
        </div>
    );
};

const sponsors = [
    {
        name: 'Pencil',
        url: 'https://www.pencil.works',
        logo: 'https://qskgg4o.app-palma.teide.app/api/files/gozjubf0qts1jkp/kb6pg6d85h9co7b/pencildomains_XNkRJSAM6O.jpg?token=',
        textColor: 'text-black hover:text-blue-700',
    },
    {
        name: 'PylarAI',
        url: 'https://www.pylar.org',
        logo: 'https://qskgg4o.app-palma.teide.app/api/files/gozjubf0qts1jkp/e4wi0lxl5bes882/pylar_0bkybYlxFE.jpg?token=',
        textColor: 'text-black hover:text-purple-600',
    },
    {
        name: 'TCGSE.com',
        url: 'https://www.tcgse.com',
        logo: 'https://qskgg4o.app-palma.teide.app/api/files/gozjubf0qts1jkp/sf414mo0siylway/tcgselogo_Gz5nikiBY3.jpg?token=',
        textColor: 'text-black hover:text-green-600',
    }
];

export default Sponsors;
