import React from 'react';
import Standings from '../components/elements/Standings';
import Sponsors from '../components/sections/Sponsors';
import HeaderStandings from '../components/ui/HeaderStandings';

const ResultsPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-28">
            <HeaderStandings />
            <main>
                <Standings />
                <Sponsors />
            </main>
        </div>
    );
};

export default ResultsPage;