import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://rgkk0kg.app-palma.teide.app');

const StoreIcons = {
  store_kritik: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/eipmi64rkp6cl66/kritik_1_zz_vqn_b2921_a07l_k2j_luj_8osm9XbWsJ.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NjUwMTYsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.ET9iO21VrQZRDjnAJ8xwazK05AhXM3ZJrIvwYiYdGIA",
  store_ingenio: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/7rew2v9kno3vvzg/ingenio_i4HzMwJuHm.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQyMzMwNDksImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.ns6z47BWbikjRcf9jXsAf2enuJUH-rIUhHsgW_gfMfo",
  store_gameria: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/hq7cswylwpj2zao/gameria_fgnkxhd_rm2_a27n_uiuxno_r_bsj2_ssa_pt_8bhteHcVAa.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NjQ5MjgsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.VsrpymkMar-78MyO0UP2fc5WJGjlnCI45G0TjH9oCJs",
  store_elite4cards: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/0tmswmjbberz0aj/elite4cards_MPg2NtTYdA.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQ3ODQ2MTMsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.h6FzCa2XHQ_ohfoopghwaZYZzktCn-7AhE74ZBl9nUE",
  store_maquina: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/0wwawyastgx5t42/mquina_jtkd8vgq_ey_zg_kueu_ol_ti_u_qev_qu5_vaf_YKnmIRfFGI.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NjQ4NDgsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.6AB4IhOh92BOUpH9JA0A1PIaFqY4Iwhg-pnnDz_E-k4",
  store_regional: "https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/u97btd2cxakpixi/store_regional_wTjlYTJhqp.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTc0NTExNzEsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.V3eWNz0FFeDajVq0qBWqrxTf5TRp9OQ8cgfAJTD0cn0",
};
const StoreUrls = {
  store_kritik: "https://www.pokemon.com/us/play-pokemon/pokemon-events/leagues/6239503/",
  store_ingenio: "https://www.pokemon.com/us/play-pokemon/pokemon-events/leagues/", /* 6238419 */
  store_elite4cards: "https://www.pokemon.com/us/play-pokemon/", /* 6239007 */
  store_gameria: "https://www.pokemon.com/us/play-pokemon/pokemon-events/leagues/4943204/",
  store_maquinatemps: "https://www.pokemon.com/us/play-pokemon/pokemon-events/leagues/3203213/",
  store_regional: "https://www.pokemon.com/us/play-pokemon/pokemon-events/pokemon-tournaments/regional-championships/",
};

const BestStandings = () => {
  const [bestStandings, setBestStandings] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [challengeIcon] = useState(null);

  useEffect(() => {
    const fetchStandingsAndEvents = async () => {
      try {
        const controller = new AbortController();
        setTimeout(() => controller.abort(), 10000);

        const standingsResponse = await pb.collection('events_standings').getFullList(200, {
          expand: 'relField1,relField2.subRelField',
          signal: controller.signal
        });

        const sortedStandings = standingsResponse.sort((a, b) => {
          if (a.victories !== b.victories) return b.victories - a.victories;
          if (a.tables !== b.tables) return b.tables - a.tables;
          return a.losses - b.losses;
        }).slice(0, 7);

        const eventIds = sortedStandings.map(standing => standing.event);
        const eventsResponse = await Promise.all(eventIds.map(id =>
          pb.collection('events_details').getOne(id, {
            expand: 'relField1,relField2.subRelField',
            signal: controller.signal
          })
        ));

        setBestStandings(sortedStandings);
        setEvents(eventsResponse);
        setError(null);
      } catch (err) {
        if (err.name === 'AbortError') {
          setError('Request timed out. Please try again.');
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchStandingsAndEvents();
  }, []);

  if (loading) return <p className="text-center text-gray-700">Loading...</p>;
  if (error) return <p className="text-center text-blue-500">Error loading data...</p>;

  return (
    <div className="max-w-7xl mx-auto p-6 text-black">
      <h2 className="text-3xl font-bold mb-8 text-center">Top 3 Standings</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {bestStandings.length > 0 ? (
          bestStandings.map((standing, index) => {
            const event = events.find(event => event.id === standing.event);
            const challengeIconKey = `store_${event?.challenge.toLowerCase()}`;
            const isBarcelonaStore = event?.challenge === "inGenio" || event?.challenge === "Elite4Cards";

            return (
              <div key={index} className="rounded-lg overflow-hidden mb-8 p-6 border border-2 border-[#f00e33] bg-[#f00e3305]">
                {event && (
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-4">{event.name}</h3>
                    <p className="text-gray-700 font-medium mb-2"><strong>Date:</strong> {new Date(event.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                    <p className="text-gray-700 font-medium mb-2"><strong>Category:</strong> {event.category}</p>
                    <p className="flex flex-row space-x-2 font-medium mb-2 items-center">
                      {isBarcelonaStore ? (
                        <span className="w-8 h-8 flex items-center justify-center rounded-full text-xl" style={{ color: '#f00e33' }}>
                          🏬
                        </span>
                      ) : (
                        challengeIcon && <img className="w-8 rounded-full" src={StoreIcons[challengeIconKey]} alt={event?.challenge} />
                      )}
                      <div className="flex flex-col justify-center bg-[#f00e3320] px-2 py-[4px] rounded-full">
                        <a className="" href={StoreUrls[challengeIconKey]} target="_blank" rel="noreferrer noopener">
                          <div className="flex flex-row justify-center items-center space-x-2">
                            <span className="flex flex-row font-bold hover:underline-2 hover:underline hover:text-[#f00e33]">
                              {isBarcelonaStore ? "Barcelona Store" : event.challenge}
                            </span>
                            <svg className="" height="21" viewBox="0 0 21 21" strokeWidth="1.9px" width="21" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd" stroke="#f00e33" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 3)">
                                <path d="m15.5.5v5h-5" transform="matrix(1 0 0 -1 0 6)" />
                                <path d="m12-.95v9.9" transform="matrix(.70710678 .70710678 -.70710678 .70710678 6.343146 -7.313708)" />
                                <path d="m7.5.5h-5c-1.1045695 0-2 .8954305-2 2v10c0 1.1045695.8954305 2 2 2h11c1.1045695 0 2-.8954305 2-2v-4" />
                              </g>
                            </svg>
                          </div>
                        </a>
                      </div>
                    </p>
                  </div>
                )}
                <p className="text-gray-700 font-medium mb-1"><strong>Result:</strong> {standing.victories}/{standing.losses}/{standing.tables}</p>
                <p className="text-gray-700 font-medium mb-1"><strong>Position:</strong> {standing.top} of {standing.total}</p>
              </div>
            );
          })
        ) : (
          <p className="text-center text-gray-700">No standings found.</p>
        )}
      </div>
    </div>
  );
};

export default BestStandings;