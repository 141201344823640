import React from 'react';
import LoginButton from '../components/elements/LoginButton.js';
import Sponsors from '../components/sections/Sponsors';

const ResultsPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-28">
            <main>
                <LoginButton />
                <Sponsors />
            </main>
        </div>
    );
};

export default ResultsPage;