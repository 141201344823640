import React from 'react';

const HeaderMerch = () => {
    return (
        <header className="bg-rose-200 text-[#f00e33] py-6">
            <div className="flex flex-col items-center mx-auto text-center py-4">
                {/*  <h1 className="flex flex-row font-ryomen items-center">
                    <span className="text-3xl px-2">MErcH</span>
                </h1> */}
                <div className="flex flex-row items-center space-x-2 mt-2">
                    <div className="flex flex-row font-ryomen items-center">
                        <span className="text-xl ">EpIC EVILs</span>
                        <span className="text-sm pt-4 pl-2">©</span>
                    </div>
                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(5 5)"><path d="m10.5 10.5-10-10z" /><path d="m10.5.5-10 10" /></g></svg>
                    <img src="https://rgkk0kg.app-palma.teide.app/api/files/jx3pmj96bz368cp/tiyyhe9ffv8f6az/inzoi_brand_4iPo15PuWX.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQ5MzA4MTEsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.eg8ItrBrqTsXYCKXrExSgXp71rCOBrxNGOVB6onyuvs"
                        alt="inZoi" className="h-5" />
                </div>
                <p className="font-bold text-lg mt-2">Available Now!</p>
                <a href="https://krafton.com/en/games/inzoi/" target="_blank" rel="noopener noreferrer">
                    <button className="mt-4 px-4 py-2 bg-[#f00e33] text-white font-bold rounded hover:bg-[#f00e3320]">
                        Shop Now
                    </button>
                </a>
            </div>
        </header>
    );
};

export default HeaderMerch;
