import React from 'react';
import Collab from '../components/sections/Collab';
import Sponsors from '../components/sections/Sponsors';
import HeaderCollab from '../components/ui/HeaderCollab';

const CollabPage = () => {
  return (
    <div className="bg-rose-100 min-h-screen py-28">
      <HeaderCollab />
      <main>
        <Collab />
        <Sponsors />
      </main>
    </div>
  );
};

export default CollabPage;
