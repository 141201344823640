import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { toast } from 'wc-toast';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [dataprivacy, setDataprivacy] = useState(false);
    const [tos, setTos] = useState(false);
    const [ads, setAds] = useState(true); // Default to true as requested
    const [submitted, setSubmitted] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [step, setStep] = useState(1); // Step 1: Registration, Step 2: Verify Email, Step 3: Message Form
    const [tempPassword, setTempPassword] = useState('');
    const [verificationChecking, setVerificationChecking] = useState(false);
    const pb = new PocketBase('https://hw0kcow4wswsg8k0gookww0o.app-palma.teide.app');

    useEffect(() => {
        // Check if user is already authenticated
        if (pb.authStore.isValid) {
            setIsAuthenticated(true);
            setEmail(pb.authStore.model?.email || '');
            setStep(3); // Skip to message form if already authenticated
        }
    }, []);

    async function handleEmailSubmission(e) {
        e.preventDefault();

        try {
            // Validate required checkboxes
            if (!dataprivacy || !tos) {
                toast.error('You must accept the Data Privacy Policy and Terms of Service');
                return;
            }

            // Generate a random password for temporary use
            const generatedPassword = generateTemporaryPassword();
            setTempPassword(generatedPassword);

            try {
                // Try logging in first in case the user already exists
                await pb.collection('contacts').authWithPassword(email, generatedPassword);
                toast.success('Login successful!');
                setStep(3); // Skip verification since the user is already logged in
            } catch (loginErr) {
                console.log('Login failed, creating new account');
                // If login fails, create a new contact record
                const userData = {
                    email,
                    password: generatedPassword,
                    passwordConfirm: generatedPassword,
                    emailVisibility: true,
                    dataprivacy,
                    tos,
                    ads
                };

                try {
                    // Create the user and request verification email
                    const newUser = await pb.collection('contacts').create(userData);
                    console.log('Account created successfully', newUser);

                    await pb.collection('contacts').requestVerification(email);
                    toast.success('Verification email sent! Please check your inbox.');
                    setStep(2); // Move to email verification step
                } catch (createErr) {
                    console.error('Account creation error:', createErr);
                    toast.error('Error creating account: ' + (createErr.message || 'Unknown error'));
                }
            }
        } catch (err) {
            console.error('Email submission error:', err);
            toast.error('Error processing your request: ' + (err.message || 'Unknown error'));
        }
    }

    // Remove handleOtpVerification as we will rely solely on status checking

    async function handleMessageSubmit(e) {
        e.preventDefault();

        try {
            // Verify authentication is still valid
            if (!pb.authStore.isValid) {
                toast.error('Your session has expired. Please log in again.');
                setStep(1); // Go back to step 1
                return;
            }

            console.log('Authenticated user ID:', pb.authStore.model.id);

            // Create message linked to authenticated contact
            const messageData = {
                name,
                message,
                contact: pb.authStore.model.id
            };

            console.log('Attempting to create message with data:', messageData);

            try {
                const record = await pb.collection('messages').create(messageData);
                console.log('Message created successfully:', record);

                toast('Message sent!', {
                    icon: { type: 'custom', content: '🎉' },
                    theme: {
                        type: 'custom',
                        style: { background: 'royalblue', color: 'white' },
                    },
                });

                setName('');
                setMessage('');
                setSubmitted(true);

                setTimeout(() => {
                    window.location.href = '/'; // Redirect to Home after 1 second
                }, 1000);
            } catch (createErr) {
                console.error('Error creating message:', createErr);
                toast.error(`Error creating message: ${createErr.message || createErr}`);
                setErrorCount(prevCount => prevCount + 1);
            }
        } catch (err) {
            console.error('Message submission error:', err);
            setErrorCount(prevCount => prevCount + 1);
            toast.error('Error submitting message. Please try again.');

            if (errorCount + 1 >= 2) {
                setSubmitted(true);
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            }
        }
    }

    async function checkVerificationStatus() {
        setVerificationChecking(true);
        toast('Checking email verification status...', {
            theme: {
                type: 'custom',
                style: { background: '#3498db', color: 'white' },
            },
        });

        try {
            // Try to authenticate with the stored credentials
            const authData = await pb.collection('contacts').authWithPassword(email, tempPassword);
            if (authData) {
                setIsAuthenticated(true);
                toast.success('Email verified successfully!');
                setStep(3); // Move to message form
            }
        } catch (error) {
            console.error('Verification check failed:', error);
            toast.error('Your email is not verified yet. Please check your inbox and spam folder.');
        } finally {
            setVerificationChecking(false);
        }
    }

    function generateTemporaryPassword() {
        // Generate a random password
        return Math.random().toString(36).slice(-10) + Math.random().toString(36).slice(-10);
    }

    // Email form (Step 1)
    const renderEmailForm = () => (
        <form onSubmit={handleEmailSubmission} className="py-8 max-w-xl mx-auto">
            <div className="mb-6">
                <label htmlFor="email" className="block text-xl font-medium text-[#f00e33]">Email*</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                />
            </div>

            <div className="mb-6 space-y-3">
                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            id="dataprivacy"
                            name="dataprivacy"
                            type="checkbox"
                            checked={dataprivacy}
                            onChange={e => setDataprivacy(e.target.checked)}
                            className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                        />
                    </div>
                    <div className="ml-3">
                        <label htmlFor="dataprivacy" className="text-md font-medium text-[#f00e33]">
                            Data Privacy Policy* (required)
                        </label>
                        <p className="text-sm text-gray-500">I agree to the data privacy policy of this website.</p>
                    </div>
                </div>

                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            id="tos"
                            name="tos"
                            type="checkbox"
                            checked={tos}
                            onChange={e => setTos(e.target.checked)}
                            className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                        />
                    </div>
                    <div className="ml-3">
                        <label htmlFor="tos" className="text-md font-medium text-[#f00e33]">
                            Terms of Service* (required)
                        </label>
                        <p className="text-sm text-gray-500">I agree to the terms of service of this website.</p>
                    </div>
                </div>

                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            id="ads"
                            name="ads"
                            type="checkbox"
                            checked={ads}
                            onChange={e => setAds(e.target.checked)}
                            className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                        />
                    </div>
                    <div className="ml-3">
                        <label htmlFor="ads" className="text-md font-medium text-gray-700">
                            Marketing Communications
                        </label>
                        <p className="text-sm text-gray-500">I agree to receive marketing communications.</p>
                    </div>
                </div>
            </div>

            <button type="submit"
                className="flex w-full items-center justify-center border-[#f00e33] border-2 hover:bg-[#f00e3320] text-[#f00e33] hover:text-[#f00e33] font-bold py-2 px-4 rounded mt-4 hover:bg-[#f00e3330] hover:border-[#f00e3330]"
            >
                Send Verification Code
            </button>
        </form>
    );

    // OTP verification form (Step 2) – now only checking verification status
    const renderOtpVerificationForm = () => (
        <div className="py-8 max-w-xl mx-auto">
            <p className="text-blue-700 mb-3">
                A verification email has been sent to <strong>{email}</strong>.<br />
                Please check your inbox and click the verification link.
            </p>
            <div className="flex flex-col gap-3">
                <button
                    type="button"
                    onClick={checkVerificationStatus}
                    disabled={verificationChecking}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors disabled:opacity-50"
                >
                    {verificationChecking ? (
                        <span className="flex items-center justify-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Checking...
                        </span>
                    ) : (
                        "I have verified my email. Check status"
                    )}
                </button>
            </div>
        </div>
    );

    // Message form (Step 3)
    const renderMessageForm = () => (
        <form onSubmit={handleMessageSubmit} className="py-8 max-w-xl mx-auto">
            <div className="mb-6 p-3 bg-green-100 rounded-md">
                <p className="text-green-600">Verified and logged in as: {pb.authStore.model?.email}</p>
            </div>

            <div className="mb-6">
                <label htmlFor="name" className="block text-xl font-medium text-[#f00e33]">Name*</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                />
            </div>

            <div className="mb-6">
                <label htmlFor="message" className="block text-xl font-medium text-[#f00e33]">Message*</label>
                <textarea
                    id="message"
                    name="message"
                    rows="4"
                    required
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                ></textarea>
            </div>

            <button type="submit"
                className="flex w-full items-center justify-center border-[#f00e33] border-2 hover:bg-[#f00e3320] text-[#f00e33] hover:text-[#f00e33] font-bold py-2 px-4 rounded mt-4 hover:bg-[#f00e3330] hover:border-[#f00e3330]"
            >
                Send Message
            </button>
        </form>
    );

    const renderCurrentStep = () => {
        switch (step) {
            case 1:
                return renderEmailForm();
            case 2:
                return renderOtpVerificationForm();
            case 3:
                return renderMessageForm();
            default:
                return renderEmailForm();
        }
    };

    return (
        <div className="p-4 text-black">
            <wc-toast></wc-toast>
            {submitted ? (
                <div className="text-center">
                    <p className="text-xl font-medium text-green-600">Thanks for contacting, redirecting to Home in 1 second</p>
                    <div className="flex justify-center mt-4">
                        <div className="loader">
                            <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(2 2)"><path d="m4.5 1.5c-2.41169541 1.37786776-4 4.02354835-4 7 0 4.418278 3.581722 8 8 8m4-1c2.2866288-1.4081018 4-4.1175492 4-7 0-4.418278-3.581722-8-8-8" /><path d="m4.5 5.5v-4h-4" /><path d="m12.5 11.5v4h4" /></g></svg>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <h2 className="text-2xl font-bold mb-4 text-center text-[#f00e33]">
                        {step === 1 ? 'Step 1: Enter Email' :
                            step === 2 ? 'Step 2: Verify Email' :
                                'Step 3: Send Your Message'}
                    </h2>
                    {renderCurrentStep()}
                </>
            )}
        </div>
    );
};

export default ContactPage;